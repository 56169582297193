.p-home {
    &_invalid {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        @include adjust-flex();
    }

    &_input,
    &_calendar {
        @include mobile-down {
            width: 100%;
        }
    }

    &_filter {
        @include adjust-flex(flex-start, flex-start);
        @include mobile-down {
            flex-direction: column;
        }
    }

    &_input {
        margin-left: rem(16);
        @include mobile-down {
            margin-left: 0;
            margin-top: rem(12);
        }
    }

    &_imageList {
        @include adjust-flex(flex-start, center);
        flex-wrap: wrap;
        margin: rem(-8);

        &-item {
            padding: rem(8);
            flex: 0 0 calc(100% / 6);
            cursor: pointer;

            @include mobile-down {
                flex: 0 0 calc(100% / 3);
            }

            .a-image {
                border-radius: rem(4);
                background-color: $white-smoke;
            }

            &:hover {
                opacity: 0.7;
                transition: opacity $transition linear;
            }
        }
    }
}
