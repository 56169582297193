.a-image {
    display: block;
    overflow: hidden;
    position: relative;
    transition: $transition-long ease;
    width: 100%;
    cursor: pointer;

    img {
        height: 100%;
        left: 0;
        object-fit: cover;
        position: absolute;
        top: 0;
        width: 100%;
    }

    &_iconClose {
        position: absolute;
        top: rem(3);
        right: rem(3);
        z-index: 1; 
    }
  
    &-inherit {
        img {
            object-fit: inherit;
        }
    }
  
    &-initial {
        img {
            object-fit: initial;
        }
    }
  
    &-contain {
        img {
            object-fit: contain;
        }
    }

    &-1x1 {
        @include aspect-ratio(1, 1);
    }

    &-4x3 {
        @include aspect-ratio(4, 3);
    }

    &-3x4 {
        @include aspect-ratio(3, 4);
    }

    &-16x9 {
        @include aspect-ratio(16, 9);
    }

    &-375x498 {
        @include aspect-ratio(375, 498);
    }
}

.a-backgroundImage {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    position: relative;

    &_iconClose {
        position: absolute;
        top: rem(3);
        right: rem(3);
        z-index: 1; 
    }

    img {
        display: none;
    }
  
    &-inherit {
        background-size: inherit;
    }
  
    &-initial {
        background-size: initial;
    }
  
    &-contain {
        background-size: contain;
    }
  
    &-1x1 {
        @include aspect-ratio;
    }
  
    &-4x3 {
        @include aspect-ratio(4, 3);
    }
  
    &-16x9 {
        @include aspect-ratio(16, 9);
    }
}
